
import { Vue, Component } from 'vue-property-decorator'

@Component({
  name: 'labelCalcList'
})
export default class extends Vue {
  private detail = {}

  get frontId () {
    return this.$route.params.id || ''
  }

  created () {
    this.getDetail()
  }

  getDetail () {
    this.$axios.get(this.$apis.preBidCalc.selectCalculateFrontByFrontId, {
      frontId: this.frontId
    }).then((res) => {
      this.detail = res || {}
    })
  }
}
